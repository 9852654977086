<template>
    <Transition name="fade">
        <div class="modal" v-if="id">
            <button @click="closeModal" class="close">+</button>
            <div class="video" v-if="video">
                <div v-html="video.embed.html"></div>
            </div>
            <div class="content" v-if="video">
                <h2>{{video.name}}</h2>
            <div class="text">
                <p v-html="video.description"></p>
            </div>
            </div>
        </div>
    </Transition>
</template>
<script>
export default {
    props: {
        videoId: String
    },
    data() {
        return {
            video: null,
            id: null,
        }
    },
     watch: { 
      	videoId: function(newVal) {
            this.id = newVal
             if (newVal) document.documentElement.style.overflow = "hidden"
                else document.documentElement.style.overflow = "auto"
          this.getVideoInfo()
        }
      },
    computed: {
        getVideoList () {
            return this.$store.getters.getVideoList
        }
    },
    methods: {
        loadVideoInfo(){
            this.$store.dispatch('loadVideoList')
        },
        async getVideoInfo(){

        if(!this.id) {
            return
        } 

        if(this.getVideoList) {
            this.video = this.getVideoList.filter(elem => elem.link.match(/[^\/]+$/)[0] == this.id)[0]
        }

	},
    closeModal() {
        this.id = null
        this.video = null
        document.documentElement.style.overflow = "auto"
        this.clearData()
    },
    clearData() {
      this.$emit('clear-data-event'); // Emit the custom event
    } 
    },
}
</script>
<style lang="scss">
    .modal {
        background-color: #000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 35%;
        padding: 3rem 0;
        z-index: 99;

        .video {
            padding: 1rem;
            iframe {
                max-width: 100%;
                height: auto;
                max-height: 90vh;
                min-height: 560px;
            }
        }
        

        .content {
            padding-right: 1rem;
            .text {
                font-size: 1rem;
                max-height: 80vh;
                height: 600px;
                overflow: hidden auto;
            }
        }

        .close {
            position: absolute;
            top: 5px;
            right: 5px;
            background: transparent;
            border-color: transparent;
            border-radius: 0;
            font-size: 2rem;
            color: #fff;
            transition: 0.3s;
            transform: rotate(45deg);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        
            &:hover, &:focus {
                color: #abff81;
            }

        }
    }

@media (max-width: 640px) {
    .modal {
        grid-template-columns: 1fr;
        grid-template-rows: 30%;

        .video {
            padding: 1rem 0.5rem 0;

            iframe {
                min-height: 220px;
            }
        }

        .content {
            padding: 1rem 1rem 0;
            height: 50vh;

            .text {
                height: 40vh;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
    
</style>