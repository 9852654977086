<template>
  <div class="filmography container">
  	<h1>Films</h1>
	<div class="filmography-wrap">
		<div class="left-bar">
			<ul class="controls">
				<li data-filter=".all">All</li>
				<li data-filter=".feature_films">Feature films</li>
				<li data-filter=".shorts_films">Short films</li>
				<!-- <li data-filter=".tv_series">TV Series</li> -->
				<li data-filter=".comerc">Commercials</li>
			</ul>
		</div>
		<div class="catalog">
			<div  v-for="(film, key) in films" :key="film" class="film all" :class="film.category" :style="{ maxWidth: width + 'px' }" ref="filmWidth">
				<router-link :to="{path: '/films', query: { name: key }}">
				<div class="content-wrap">
					<img :src="filmsPhoto(film.thumbnail)" width="393" height="590" :alt="film.name" v-if="!film.role" >
					<lazy-component wrapper-tag="section">
					<videoFilm
						v-if="film.role"
						:src="urlAttach(film.role)"
						:poster="filmsPhoto(film.thumbnail)"
						:mobile="urlAttach(film.roleM)"
					/>
					<!-- <video-background 
						ref="filmItem"
						class="preview"
						v-if="film.role"
						:data-src="urlAttach(film.role)"
						src=""
						:poster="filmsPhoto(film.thumbnail)"
						:sources="[
							{src: urlAttach(film.role), res: 900, autoplay: true}, 
							{src: urlAttach(film.roleM), res: 820, autoplay: true}
						]"
					>
					</video-background> -->
					</lazy-component>
					<!-- <Vue3Lottie v-if="film.role" :animationData="urlAttach(film.role)" /> -->
					<div class="content">
						<router-link :to="{path: '/films', query: { name: key }}">
							<h4>{{film.name}}</h4>
							<p v-html="film.short_descriprion" ></p>
						</router-link>
					</div>
				</div>
				<!-- <h4 class="fill-title">{{film.name}}</h4> -->
				</router-link>
			</div>

			<div class="film all comerc" v-for="item in commercials" :key="item" :style="{ maxWidth: width + 'px' }" ref="filmWidth">
				<div class="content-wrap">
					<videoFilm
						v-if="item.role"
						:src="urlAttach(item.role)"
						:poster="getImageVideo(item.id)"
						:mobile="urlAttach(item.roleM)"
					/>
					<!-- <video-background 
						class="preview"
						v-if="item.role"
						:src="urlAttach(item.role)"
						:poster="getImageVideo(item.id)"
						:sources="[
							{src: urlAttach(item.role), res: 900, autoplay: true}, 
							{src: urlAttach(item.roleM), res: 820, autoplay: true}
							
						]"
					></video-background> -->
					<img :class="{absolute: item.role}" v-if="getImageVideo" :src="getImageVideo(item.id)" width="393" height="590" :alt="item.name">
					<div class="content" @click="videoId = item.id">
						<h4 >{{item.name}}</h4>
					</div>
				</div>
				<!-- <h4>{{item.name}}</h4> -->
			</div>
		</div>
		
			<VideoModal :videoId="videoId" @clear-data-event="clearData"/>
	</div>
	
  </div>
</template>
<style scoped lang="scss">
	@import "../assets/scss/components/home.scss";
</style>
<script>
// @ is an alias to /src

import mixitup from 'mixitup'
import VideoBackground from 'vue-responsive-video-background-player'
import VideoModal from '@/components/modalVideo.vue'
//import { Vue3Lottie } from 'vue3-lottie'
//import films from '../../public/films.json'
//import commercial from '../../public/commercial.json'
import VueLazyload from 'vue-lazyload'
import videoFilm from '@/components/VideoBackground.vue'

export default {
	components: {
		mixitup,
		VideoModal,
		VideoBackground,
		//Vue3Lottie
		VueLazyload,
		videoFilm
	},
 data () {
    return {
    	films: {},
    	width: '',
		commercials: null,
		videoId: null
    }
  },
  created(){
	this.getCommercials()

	this.getFilms()

	this.loadVideoInfo()
	
  },
  beforeUpdate(){
	setTimeout(() => {
		this.mixitup()
	}, 500);
  },
  computed: {
	getVideoList () {
		return this.$store.getters.getVideoList
	}
  },
  mounted() {
   setTimeout(() => {
		this.itemWidth()		
	//	this.itemVideo()
	}, 300);
  },
  methods: {
	clearData() {
      this.videoId = null; // Clear the data
    },
	loadVideoInfo(){
		this.$store.dispatch('loadVideoList')
	},
  	mixitup () {
  		let containerEl = document.querySelector('.filmography-wrap .catalog');
    	let mixer = mixitup(containerEl, {
			    selectors: {
			        target: '.film'
			    },
				 load: {
					filter: '.all',
				},
			    animation: {
			        duration: 500
			    }
			});

    	
  	},
	filmsPhoto (url) {
		return require(`../assets/images/${url}`);
	},
	itemWidth() {
		 	let filmWidth = this.$refs.filmWidth
			this.width = filmWidth[0].offsetWidth
	},
	async itemVideo() {
		let filmWidth = this.$refs.videoRefs
		this.prerole = filmWidth
		for (let index = 0; index < filmWidth.length; index++) {
			setTimeout(() => {
				this.loadVideo(filmWidth[index])
			}, 500);
		}

	},
	async loadVideo(filmWidth){
		try {
			await filmWidth.load()		
				filmWidth.play()				
		} catch (error) {
			console.error('Autoplay with sound is not allowed:', error);
		}				
	},
	getFilms(){
		fetch("../../films.json")
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			// for (const key in data) {
			// 	if (Object.hasOwnProperty.call(data, key)) {
			// 		const element = data[key];
					
			// 		setInterval(() => {
			// 			this.films[key] = data[key]
			// 		}, 1000);
			// 	//	console.log(this.films)
			// 	}
			// }
				this.films = data
				
		});
	},
	getCommercials(){
		fetch("../../commercial.json")
		.then((response) => {
			return response.json();
		})
		.then((data) => {
				this.commercials = data.items
				this.$store.dispatch('loadVideoInfo', data.items)
		});
	},
	urlAttach (url) {
			return require(`../assets/images/preroll/${url}`);
	},
	videoUrl(VideoId){
		if(VideoId) {
			return `https://player.vimeo.com/video/${VideoId}?&title=0&byline=0&portrait=0`
		}

		return ''
	},
	getVideoId(link) {
		return link.match(/[^\/]+$/)[0]
	},
	getImageVideo(id){
		let video;

			if(this.getVideoList) {
				video = this.getVideoList.filter(video => video.link.match(/[^\/]+$/)[0] == id)

				return video[0] ? video[0].pictures.base_link : ''
			}
	}
	
  }


}
</script>
