<template>
  <v-header></v-header>
  <div class="content">
    <router-view/>
  </div>
  <v-footer></v-footer>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&display=swap');
  
  
  @import "src/assets/scss/style.scss";
</style>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    'v-header': Header,
    'v-footer': Footer
  },
  watch: {
  '$route' (to) {
    document.title = to.meta.title 
    document.querySelector('head meta[name="description"]').setAttribute('content', to.meta.description)

    let fullHref = window.location.protocol + "//" + window.location.host + this.$route.fullPath

    document.querySelector('head link[rel="canonical"]').setAttribute('href', fullHref)
  }
  }
}
</script>
