import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      count: 0,
      videoList: [],
      videosIdList: []
    }
  },
  actions: {
    async loadVideoList({ commit, dispatch }){
      fetch("../../commercial.json")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          
          commit('videosID',  data.items)
          data.items.forEach(element => {

            dispatch('loadVideoInfo', element)
          });
        });

       

      },
    async loadVideoInfo({commit, state}, videoInfo){

      if(!videoInfo.id) {
          return
      }

      if(state.videoList.find(video => video.link.match(/[^\/]+$/)[0] == videoInfo.id)) {
        return
      }

      const requestOptions = {
                  method: 'GET',
                  headers: { 
                      'Accept' : 'application/vnd.vimeo.*+json;version=3.4',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + '617667e76f490ec6394d1e5ebf108f31'
                  }
                }
          const res = await fetch(
                    'https://api.vimeo.com/videos/'+videoInfo.id,
                      requestOptions
                  )

          const video = await res.json()

          commit('setVideoInfo', {vimeo:video, info:videoInfo})


    }
  }, 
  mutations: {
    setVideoInfo(state, video){
      if(!video && !video.length < 1) {
        return
      }
      const regex = /\\n|\\r\\n|\\n\\r|\\r/g;

      let description;

      
      if(video.info.name) {
        video.vimeo.name = video.info.name
      }
     
      if(!video.info.description) {
        description = JSON.stringify(video.vimeo.description).replaceAll(regex, '<br>');
        video.vimeo.description = description
       
      } else {
        video.vimeo.description = video.info.description
      }
       
      state.videoList.push(video.vimeo)

    },
    videosID(state, listId){
      if(!listId) {
        return
      }

      state.videosIdList = listId
    },
    increment (state) {
      state.count++
    }
  },
  getters: {
    getVideoList(state) {
      return state.videoList
    }
  }
})