<!-- VideoBackground.vue -->

<template>
  <video-background 
    class="preview"
    ref="video"
    :src="src"
    :poster="poster"
    :sources="[
							{src: src, res: 900, autoplay: true, muted: true}, 
							{src: mobile, res: 820, autoplay: true, muted: true}
						]"
    @loadedmetadata="startAutoplay"
  ></video-background>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
export default {
  props: {
    src: String,
    poster: String,
    mobile: String,
  },
  	components: {
		VideoBackground,
	},
  // mounted() {
  //  this.$refs.video.player.play()
  // },
   methods: {
    startAutoplay() {
      // Check if the device is iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
      // Check if the video is not playing yet and it's iOS
      if (this.$refs.video && !this.$refs.video.player.playing && isIOS) {
        // Start playback on user interaction (like a tap)
        document.addEventListener('click', this.playOnUserInteraction);
      }
    },
    playOnUserInteraction() {
      // Remove the click event listener
      document.removeEventListener('click', this.playOnUserInteraction);

      // Play the video
      this.$refs.video.player.play();
    },
  },
};
</script>
